import { ClimateZone } from '../types';

export const climateZones: ClimateZone[] = [
  {
    id: 'pacific-northwest',
    name: 'Pacific Northwest',
    description: 'High rainfall and humidity create ideal conditions for moss growth',
    riskLevel: 'high',
    recommendations: [
      'Install zinc or copper strips near roof peak',
      'Regular gutter cleaning (3-4 times per year)',
      'Maintain tree canopy away from roof',
      'Professional inspection twice yearly'
    ]
  },
  {
    id: 'northeast',
    name: 'Northeast',
    description: 'Cold winters and moderate rainfall with seasonal moss growth',
    riskLevel: 'medium',
    recommendations: [
      'Clear snow accumulation promptly',
      'Annual professional cleaning',
      'Install proper ventilation',
      'Regular gutter maintenance'
    ]
  },
  {
    id: 'southeast',
    name: 'Southeast',
    description: 'High humidity and warm temperatures favor algae growth',
    riskLevel: 'high',
    recommendations: [
      'Use algae-resistant shingles',
      'Install copper-infused strips',
      'Improve roof ventilation',
      'Regular professional cleaning'
    ]
  },
  {
    id: 'midwest',
    name: 'Midwest',
    description: 'Variable weather with cold winters and humid summers',
    riskLevel: 'medium',
    recommendations: [
      'Install proper attic insulation',
      'Monitor ice dam formation',
      'Seasonal gutter maintenance',
      'Check ventilation systems regularly'
    ]
  },
  {
    id: 'southwest',
    name: 'Southwest',
    description: 'Hot, dry climate with occasional monsoon seasons',
    riskLevel: 'low',
    recommendations: [
      'Monitor for dust accumulation',
      'Check for sun damage',
      'Inspect after monsoon rains',
      'Maintain proper drainage systems'
    ]
  },
  {
    id: 'rocky-mountain',
    name: 'Rocky Mountain',
    description: 'High altitude with heavy snowfall and intense sun exposure',
    riskLevel: 'medium',
    recommendations: [
      'Install snow guards',
      'Regular snow removal',
      'UV-resistant treatments',
      'Monitor for freeze-thaw damage'
    ]
  },
  {
    id: 'great-plains',
    name: 'Great Plains',
    description: 'Extreme temperature variations with strong storms',
    riskLevel: 'medium',
    recommendations: [
      'Storm-resistant materials',
      'Regular hail damage inspection',
      'Wind protection measures',
      'Enhanced drainage systems'
    ]
  },
  {
    id: 'coastal-california',
    name: 'Coastal California',
    description: 'Mild temperatures with marine layer influence',
    riskLevel: 'medium',
    recommendations: [
      'Salt-resistant treatments',
      'Regular moisture checks',
      'Marine growth prevention',
      'Fog moisture management'
    ]
  },
  {
    id: 'hawaii-pacific',
    name: 'Hawaii & Pacific',
    description: 'Tropical climate with high humidity and rainfall',
    riskLevel: 'high',
    recommendations: [
      'Mold-resistant treatments',
      'Enhanced ventilation systems',
      'Quarterly inspections',
      'Hurricane-resistant materials'
    ]
  },
  {
    id: 'alaska',
    name: 'Alaska',
    description: 'Extreme cold with long periods of snow cover',
    riskLevel: 'high',
    recommendations: [
      'Heavy snow load protection',
      'Ice dam prevention',
      'Extreme weather materials',
      'Enhanced insulation systems'
    ]
  },
  {
    id: 'mid-atlantic',
    name: 'Mid-Atlantic',
    description: 'Mixed climate with all four seasons',
    riskLevel: 'medium',
    recommendations: [
      'Seasonal maintenance schedule',
      'Storm preparation measures',
      'Regular gutter cleaning',
      'Mixed-weather protection'
    ]
  },
  {
    id: 'desert-southwest',
    name: 'Desert Southwest',
    description: 'Extremely hot and dry with intense sun exposure',
    riskLevel: 'low',
    recommendations: [
      'UV protection coatings',
      'Heat-reflective materials',
      'Dust protection measures',
      'Monsoon preparation'
    ]
  }
];