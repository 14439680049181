import React from 'react';
import { Shield, AlertTriangle, Wrench, Droplets, TreePine, Thermometer } from 'lucide-react';
import type { RoofCondition } from '../types';
import WeatherAnalysis from './WeatherAnalysis';

interface Props {
  condition: RoofCondition;
  onClose: () => void;
}

export default function RecommendationDisplay({ condition, onClose }: Props) {
  const getRecommendations = () => {
    const recommendations = [];
    
    // Weather-based recommendations
    recommendations.push({
      icon: <Droplets className="h-5 w-5" />,
      text: 'Install moisture barriers in valleys and critical areas'
    });

    recommendations.push({
      icon: <TreePine className="h-5 w-5" />,
      text: condition.shadeLevel === 'high' 
        ? 'Trim overhanging branches to increase sunlight exposure'
        : 'Monitor nearby vegetation growth'
    });

    recommendations.push({
      icon: <Thermometer className="h-5 w-5" />,
      text: 'Install temperature-monitoring system for early detection'
    });

    // Shade-based recommendations
    if (condition.shadeLevel === 'high') {
      recommendations.push({
        icon: <Wrench className="h-5 w-5" />,
        text: 'Install additional roof ventilation'
      }, {
        icon: <Wrench className="h-5 w-5" />,
        text: 'Consider installing copper or zinc strips at roof peaks'
      });
    }

    // Age-based recommendations
    if (condition.age > 15) {
      recommendations.push({
        icon: <Shield className="h-5 w-5" />,
        text: 'Schedule professional inspection'
      }, {
        icon: <Wrench className="h-5 w-5" />,
        text: 'Consider protective roof coating application'
      });
    }

    // Material-specific recommendations
    switch (condition.material) {
      case 'asphalt':
        recommendations.push({
          icon: <Wrench className="h-5 w-5" />,
          text: 'Use gentle cleaning methods to preserve shingles'
        }, {
          icon: <Shield className="h-5 w-5" />,
          text: 'Consider algae-resistant shingles for next replacement'
        });
        break;
      case 'wood':
        recommendations.push({
          icon: <Droplets className="h-5 w-5" />,
          text: 'Apply preventive treatments bi-annually'
        }, {
          icon: <Wrench className="h-5 w-5" />,
          text: 'Ensure proper drainage and water flow'
        });
        break;
      case 'metal':
        recommendations.push({
          icon: <Shield className="h-5 w-5" />,
          text: 'Check for scratches or coating damage'
        }, {
          icon: <Wrench className="h-5 w-5" />,
          text: 'Maintain protective coating integrity'
        });
        break;
      case 'tile':
        recommendations.push({
          icon: <Wrench className="h-5 w-5" />,
          text: 'Inspect for cracked or loose tiles regularly'
        }, {
          icon: <Droplets className="h-5 w-5" />,
          text: 'Clean valleys and gutters thoroughly'
        });
        break;
    }

    return recommendations;
  };

  const recommendations = getRecommendations();

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-xl max-w-4xl w-full max-h-[90vh] overflow-y-auto">
        <div className="p-6">
          <div className="flex justify-between items-start mb-6">
            <h2 className="text-2xl font-bold text-gray-900">NoMossRoof.com Analysis Report</h2>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500"
            >
              <span className="sr-only">Close</span>
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          <div className="bg-emerald-50 border border-emerald-200 rounded-lg p-4 mb-6">
            <div className="flex items-start">
              <Shield className="h-5 w-5 text-emerald-600 mt-1" />
              <div className="ml-3">
                <h3 className="text-emerald-800 font-medium">Assessment Summary</h3>
                <p className="text-emerald-700 text-sm mt-1">
                  Location: {condition.city}, {condition.state}<br />
                  Roof Material: {condition.material}<br />
                  Age: {condition.age} years<br />
                  Shade Level: {condition.shadeLevel}
                </p>
              </div>
            </div>
          </div>

          <div className="space-y-8">
            <WeatherAnalysis 
              city={condition.city}
              state={condition.state}
              shadeLevel={condition.shadeLevel}
            />

            <div>
              <h3 className="text-lg font-medium text-gray-900 mb-4">Recommended Actions</h3>
              <div className="grid gap-4 sm:grid-cols-2">
                {recommendations.map((rec, index) => (
                  <div key={index} className="flex items-start p-4 bg-white border rounded-lg">
                    <span className="flex-shrink-0 p-2 bg-emerald-50 rounded-full text-emerald-600">
                      {rec.icon}
                    </span>
                    <span className="ml-3 text-gray-700">{rec.text}</span>
                  </div>
                ))}
              </div>
            </div>

            {condition.existingMoss && (
              <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-4">
                <div className="flex items-start">
                  <AlertTriangle className="h-5 w-5 text-yellow-600 mt-1" />
                  <div className="ml-3">
                    <h3 className="text-yellow-800 font-medium">Existing Moss Alert</h3>
                    <p className="text-yellow-700 text-sm mt-1">
                      Immediate attention recommended. Contact NoMossRoof.com experts or schedule
                      a professional inspection to assess the extent of moss growth.
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="mt-8 pt-6 border-t border-gray-200">
            <p className="text-sm text-gray-500">
              Report generated by NoMossRoof.com based on your location, weather patterns, and roof conditions.
              For the most accurate assessment, please consult with a local roofing professional.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}