import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft, HelpCircle, Plus, Minus } from 'lucide-react';

interface FAQ {
  question: string;
  answer: string;
}

export default function FAQPage() {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  // Keyboard navigation
  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (openIndex !== null) {
        if (e.key === 'ArrowDown' && openIndex < faqs.length - 1) {
          setOpenIndex(openIndex + 1);
        } else if (e.key === 'ArrowUp' && openIndex > 0) {
          setOpenIndex(openIndex - 1);
        } else if (e.key === 'Escape') {
          setOpenIndex(null);
        }
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [openIndex]);

  const faqs: FAQ[] = [
    {
      question: "Does soft washing remove moss?",
      answer: "Yes, soft washing is highly effective at removing moss from roofs. The process uses specialized, biodegradable cleaning solutions that not only remove existing moss but also eliminate the spores that could lead to regrowth. Unlike pressure washing, soft washing is gentle yet thorough, ensuring complete moss removal without damaging your roofing materials."
    },
    {
      question: "Is it okay to soft wash a roof?",
      answer: "Absolutely! Soft washing is the recommended method for cleaning roofs by professional contractors and manufacturers. It's safe for all types of roofing materials because it uses low pressure combined with specialized cleaning solutions. Unlike high-pressure washing, which can damage shingles and void warranties, soft washing effectively cleans without risking damage to your roof's integrity."
    },
    {
      question: "What is the fastest way to remove moss from a roof?",
      answer: "Professional soft washing is the quickest and most effective method for removing moss from a roof. While DIY methods exist, they often take longer and may not be as thorough. Our soft washing service can remove moss completely in a single session, typically taking just a few hours depending on roof size and moss coverage. Plus, our treatment includes preventive measures to inhibit future moss growth."
    },
    {
      question: "How long does soft washing a roof last?",
      answer: "A professional soft wash treatment typically keeps your roof moss-free for 2-5 years, depending on environmental conditions. Factors affecting longevity include local climate, shade coverage, and surrounding vegetation. Our treatment includes protective agents that continue working long after the initial cleaning, helping to prevent new moss growth. Regular inspections and maintenance can further extend these benefits."
    }
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      <Link to="/" className="inline-flex items-center text-emerald-600 hover:text-emerald-700 mb-6">
        <ArrowLeft className="h-5 w-5 mr-2" />
        Back to Home
      </Link>

      <div className="max-w-4xl mx-auto">
        <div className="flex items-center mb-8">
          <HelpCircle className="h-10 w-10 text-emerald-600 mr-4" />
          <h1 className="text-3xl font-bold text-gray-900">Frequently Asked Questions</h1>
        </div>

        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <div 
              key={index} 
              className="bg-white rounded-lg shadow-md"
              role="region"
              aria-labelledby={`faq-${index}`}
            >
              <button
                id={`faq-${index}`}
                className="w-full px-6 py-4 flex items-center justify-between text-left"
                onClick={() => setOpenIndex(openIndex === index ? null : index)}
                aria-expanded={openIndex === index}
                aria-controls={`faq-content-${index}`}
                tabIndex={0}
              >
                <span className="font-medium text-gray-900">{faq.question}</span>
                {openIndex === index ? (
                  <Minus className="h-5 w-5 text-emerald-600" aria-hidden="true" />
                ) : (
                  <Plus className="h-5 w-5 text-emerald-600" aria-hidden="true" />
                )}
              </button>
              {openIndex === index && (
                <div 
                  id={`faq-content-${index}`}
                  className="px-6 pb-4"
                >
                  <p className="text-gray-600">{faq.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="mt-8 bg-emerald-50 rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">Still Have Questions?</h2>
          <p className="text-gray-700 mb-4">
            Our experts are here to help you understand the best solutions for your specific roof situation.
          </p>
          <div className="flex flex-col sm:flex-row gap-4">
            <a href="tel:877-240-2506" className="inline-flex items-center justify-center px-6 py-3 border border-emerald-600 text-emerald-600 font-medium rounded-md hover:bg-emerald-50">
              Call (877) 240-2506
            </a>
            <a 
              href="//leads.leadsmartinc.com/?api_key=eccf565586cda416df8b89f66df641fee9a1bcb8&affiliate_source=albertowaizel1&category=191&funnel=3&buttons=btn-success&step=1"
              className="inline-flex items-center justify-center px-6 py-3 bg-emerald-600 text-white font-medium rounded-md hover:bg-emerald-700"
              target="_blank"
              rel="noopener noreferrer"
            >
              Get a Free Estimate
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}