import React, { useState, useEffect } from 'react';
import { AlertCircle } from 'lucide-react';
import type { RoofCondition } from '../types';

interface Props {
  onSubmit: (data: RoofCondition) => void;
}

export default function AssessmentForm({ onSubmit }: Props) {
  const [formData, setFormData] = useState<RoofCondition>(() => {
    // Load saved form data from localStorage
    const saved = localStorage.getItem('assessmentFormData');
    return saved ? JSON.parse(saved) : {
      city: '',
      state: '',
      shadeLevel: 'medium',
      age: 0,
      material: '',
      existingMoss: false
    };
  });

  // Save form data to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('assessmentFormData', JSON.stringify(formData));
  }, [formData]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
    // Clear saved form data after successful submission
    localStorage.removeItem('assessmentFormData');
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? (e.target as HTMLInputElement).checked : value
    }));
  };

  const states = [
    'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA',
    'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
    'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
    'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
    'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
  ];

  return (
    <form onSubmit={handleSubmit} className="max-w-lg mx-auto space-y-6 p-6 bg-white rounded-lg shadow-md">
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label htmlFor="city" className="block text-sm font-medium text-gray-700">City</label>
          <input
            type="text"
            id="city"
            name="city"
            value={formData.city}
            onChange={handleChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
          />
        </div>
        <div>
          <label htmlFor="state" className="block text-sm font-medium text-gray-700">State</label>
          <select
            id="state"
            name="state"
            value={formData.state}
            onChange={handleChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
          >
            <option value="">Select State</option>
            {states.map(state => (
              <option key={state} value={state}>{state}</option>
            ))}
          </select>
        </div>
      </div>

      <div>
        <label htmlFor="material" className="block text-sm font-medium text-gray-700">Roof Material</label>
        <select
          id="material"
          name="material"
          value={formData.material}
          onChange={handleChange}
          required
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
        >
          <option value="">Select Material</option>
          <option value="asphalt">Asphalt Shingles</option>
          <option value="wood">Wood Shakes</option>
          <option value="metal">Metal</option>
          <option value="tile">Tile</option>
        </select>
      </div>

      <div>
        <label htmlFor="age" className="block text-sm font-medium text-gray-700">Roof Age (years)</label>
        <input
          type="number"
          id="age"
          name="age"
          min="0"
          max="100"
          value={formData.age}
          onChange={handleChange}
          required
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
        />
      </div>

      <div>
        <label htmlFor="shadeLevel" className="block text-sm font-medium text-gray-700">Shade Level</label>
        <select
          id="shadeLevel"
          name="shadeLevel"
          value={formData.shadeLevel}
          onChange={handleChange}
          required
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
        >
          <option value="low">Low (Full Sun)</option>
          <option value="medium">Medium (Partial Shade)</option>
          <option value="high">High (Heavy Shade)</option>
        </select>
      </div>

      <div className="flex items-center">
        <input
          type="checkbox"
          id="existingMoss"
          name="existingMoss"
          checked={formData.existingMoss}
          onChange={handleChange}
          className="h-4 w-4 text-emerald-600 focus:ring-emerald-500 border-gray-300 rounded"
        />
        <label htmlFor="existingMoss" className="ml-2 block text-sm text-gray-700">
          Current moss growth on roof
        </label>
      </div>

      <div className="flex items-center justify-between pt-4">
        <div className="flex items-center text-yellow-600">
          <AlertCircle className="h-5 w-5 mr-2" />
          <span className="text-sm">All fields required for accurate assessment</span>
        </div>
        <button
          type="submit"
          className="bg-emerald-600 text-white px-6 py-2 rounded-md hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
        >
          Get Assessment
        </button>
      </div>
    </form>
  );
}