import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft, Shield, Check, Wrench, Calendar } from 'lucide-react';

export default function PreventionPage() {
  return (
    <div className="container mx-auto px-4 py-8">
      <Link to="/" className="inline-flex items-center text-emerald-600 hover:text-emerald-700 mb-6">
        <ArrowLeft className="h-5 w-5 mr-2" />
        Back to Home
      </Link>

      <div className="max-w-4xl mx-auto">
        <div className="flex items-center mb-8">
          <Shield className="h-10 w-10 text-emerald-600 mr-4" />
          <h1 className="text-3xl font-bold text-gray-900">Prevention First</h1>
        </div>

        <div className="space-y-8">
          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-xl font-semibold mb-4">Key Prevention Strategies</h2>
            <div className="grid md:grid-cols-2 gap-6">
              {[
                {
                  icon: <Check className="h-6 w-6" />,
                  title: "Regular Inspections",
                  description: "Conduct bi-annual roof inspections to catch early signs of moss growth"
                },
                {
                  icon: <Wrench className="h-6 w-6" />,
                  title: "Preventive Treatments",
                  description: "Apply zinc or copper-based treatments before moss appears"
                },
                {
                  icon: <Calendar className="h-6 w-6" />,
                  title: "Maintenance Schedule",
                  description: "Follow a seasonal maintenance plan to prevent moss establishment"
                }
              ].map((item, index) => (
                <div key={index} className="flex items-start">
                  <span className="flex-shrink-0 p-2 bg-emerald-50 rounded-full text-emerald-600">
                    {item.icon}
                  </span>
                  <div className="ml-4">
                    <h3 className="font-medium text-gray-900">{item.title}</h3>
                    <p className="text-gray-600 mt-1">{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="bg-emerald-50 rounded-lg p-6">
            <h2 className="text-xl font-semibold mb-4">Why Prevention Matters</h2>
            <p className="text-gray-700">
              Preventing moss growth is significantly more cost-effective than removing established moss. 
              Early prevention helps maintain your roof's integrity, extends its lifespan, and protects 
              your investment in your home.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}