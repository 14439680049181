import React from 'react';
import { Cloud, Sun, Thermometer, Wind } from 'lucide-react';

interface WeatherRisk {
  factor: string;
  icon: React.ReactNode;
  risk: 'low' | 'moderate' | 'high';
  description: string;
}

interface Props {
  city: string;
  state: string;
  shadeLevel: string;
}

export default function WeatherAnalysis({ city, state, shadeLevel }: Props) {
  // This is a simplified risk assessment based on location and shade
  const getWeatherRisks = (): WeatherRisk[] => {
    const isNorthwest = ['WA', 'OR', 'ID'].includes(state);
    const isNortheast = ['ME', 'NH', 'VT', 'MA', 'RI', 'CT', 'NY', 'PA', 'NJ'].includes(state);
    const isSoutheast = ['FL', 'GA', 'SC', 'NC', 'AL', 'MS', 'LA'].includes(state);
    
    return [
      {
        factor: 'Moisture Level',
        icon: <Cloud className="h-5 w-5" />,
        risk: isNorthwest ? 'high' : isSoutheast ? 'high' : 'moderate',
        description: `${isNorthwest ? 'High' : isSoutheast ? 'High humidity' : 'Moderate'} moisture levels ${
          isNorthwest ? 'from frequent rainfall' : isSoutheast ? 'and precipitation' : 'may'} support moss growth`
      },
      {
        factor: 'Temperature',
        icon: <Thermometer className="h-5 w-5" />,
        risk: isNorthwest ? 'moderate' : isSoutheast ? 'high' : 'low',
        description: `${
          isNortheast ? 'Cold winters and mild summers create' :
          isSoutheast ? 'Warm, humid conditions create' :
          'Current temperature patterns show'
        } ${isNorthwest ? 'favorable' : 'potential'} conditions for moss growth`
      },
      {
        factor: 'Sun Exposure',
        icon: <Sun className="h-5 w-5" />,
        risk: shadeLevel === 'high' ? 'high' : shadeLevel === 'medium' ? 'moderate' : 'low',
        description: `${
          shadeLevel === 'high' ? 'Limited sunlight creates ideal conditions for moss' :
          shadeLevel === 'medium' ? 'Partial shade requires monitoring' :
          'Good sun exposure helps prevent moss'
        }`
      },
      {
        factor: 'Ventilation Needs',
        icon: <Wind className="h-5 w-5" />,
        risk: isNorthwest || isNortheast ? 'high' : 'moderate',
        description: `${
          isNorthwest ? 'High rainfall requires excellent ventilation' :
          isNortheast ? 'Winter conditions demand good airflow' :
          'Proper ventilation helps prevent moisture accumulation'
        }`
      }
    ];
  };

  const getRiskColor = (risk: string) => {
    switch (risk) {
      case 'high':
        return 'text-red-600 bg-red-50';
      case 'moderate':
        return 'text-yellow-600 bg-yellow-50';
      default:
        return 'text-green-600 bg-green-50';
    }
  };

  const risks = getWeatherRisks();

  return (
    <div className="space-y-4">
      <h3 className="text-lg font-medium text-gray-900">Weather & Environmental Analysis</h3>
      <div className="grid gap-4 sm:grid-cols-2">
        {risks.map((risk, index) => (
          <div key={index} className="border rounded-lg p-4 bg-white">
            <div className="flex items-center space-x-2 mb-2">
              <span className={`p-2 rounded-full ${getRiskColor(risk.risk)}`}>
                {risk.icon}
              </span>
              <span className="font-medium">{risk.factor}</span>
            </div>
            <div className={`inline-block px-2 py-1 rounded-full text-sm font-medium mb-2 ${getRiskColor(risk.risk)}`}>
              {risk.risk.charAt(0).toUpperCase() + risk.risk.slice(1)} Risk
            </div>
            <p className="text-sm text-gray-600">{risk.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}