import React from 'react';
import { Home, Info, Map, Phone } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function Header() {
  return (
    <header className="bg-gradient-to-r from-emerald-600 to-teal-700 text-white shadow-lg">
      <div className="container mx-auto px-4 py-2">
        <div className="flex items-center justify-between">
          <Link to="/" className="flex items-center space-x-2 text-xl font-bold">
            <Home className="h-6 w-6" />
            <span>NoMossRoof.com</span>
          </Link>
          
          <div className="flex items-center space-x-6">
            <a href="tel:877-240-2506" className="flex items-center space-x-1 hover:text-emerald-200">
              <Phone className="h-5 w-5" />
              <span>(877) 240-2506</span>
            </a>
            <Link to="/zones" className="hidden md:flex items-center space-x-1 hover:text-emerald-200">
              <Map className="h-5 w-5" />
              <span>Climate Zones</span>
            </Link>
            <a 
              href="//leads.leadsmartinc.com/?api_key=eccf565586cda416df8b89f66df641fee9a1bcb8&affiliate_source=albertowaizel1&category=191&funnel=3&buttons=btn-success&step=1" 
              className="bg-emerald-500 hover:bg-emerald-400 text-white px-4 py-2 rounded-md transition-colors"
              target="_blank"
              rel="noopener noreferrer"
            >
              Get Free Estimate
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}