import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { Shield, Droplets, TreePine, HelpCircle } from 'lucide-react';
import Header from './components/Header';
import AssessmentForm from './components/AssessmentForm';
import RecommendationDisplay from './components/RecommendationDisplay';
import PreventionPage from './pages/PreventionPage';
import MoisturePage from './pages/MoisturePage';
import EnvironmentalPage from './pages/EnvironmentalPage';
import FAQPage from './pages/FAQPage';
import { climateZones } from './data/climateZones';
import type { RoofCondition } from './types';

function App() {
  const [assessmentResult, setAssessmentResult] = useState<RoofCondition | null>(null);

  const handleAssessment = (data: RoofCondition) => {
    setAssessmentResult(data);
  };

  return (
    <Router>
      <div className="min-h-screen bg-gray-50">
        <Header />
        
        <Routes>
          <Route path="/" element={
            <main className="container mx-auto px-4 py-8">
              <section className="text-center mb-12">
                <h1 className="text-4xl font-bold text-gray-900 mb-4">
                  Professional Roof Soft Washing & Moss Prevention
                </h1>
                <p className="text-xl text-gray-600 max-w-2xl mx-auto mb-6">
                  Protect your investment with expert roof care. Our soft washing technique safely eliminates moss 
                  while preserving your roof's integrity.
                </p>
                <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
                  <a href="tel:877-240-2506" className="text-lg font-semibold text-emerald-600 hover:text-emerald-700">
                    Call Now: (877) 240-2506
                  </a>
                  <a 
                    href="//leads.leadsmartinc.com/?api_key=eccf565586cda416df8b89f66df641fee9a1bcb8&affiliate_source=albertowaizel1&category=191&funnel=3&buttons=btn-success&step=1" 
                    className="bg-emerald-600 text-white px-6 py-3 rounded-md hover:bg-emerald-700 transition-colors text-lg font-semibold"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Get Your Free Estimate Today
                  </a>
                </div>
              </section>

              <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6 mb-12">
                <div className="bg-white p-6 rounded-lg shadow-md">
                  <div className="flex items-center mb-4">
                    <Shield className="h-6 w-6 text-emerald-600" />
                    <h2 className="text-xl font-semibold ml-2">Prevention</h2>
                  </div>
                  <p className="text-gray-600 mb-4">Learn effective strategies to prevent moss growth and protect your roof.</p>
                  <Link to="/prevention" className="text-emerald-600 hover:text-emerald-700 font-medium">
                    Learn more →
                  </Link>
                </div>

                <div className="bg-white p-6 rounded-lg shadow-md">
                  <div className="flex items-center mb-4">
                    <Droplets className="h-6 w-6 text-emerald-600" />
                    <h2 className="text-xl font-semibold ml-2">Moisture Control</h2>
                  </div>
                  <p className="text-gray-600 mb-4">Discover how proper moisture management keeps your roof moss-free.</p>
                  <Link to="/moisture" className="text-emerald-600 hover:text-emerald-700 font-medium">
                    Learn more →
                  </Link>
                </div>

                <div className="bg-white p-6 rounded-lg shadow-md">
                  <div className="flex items-center mb-4">
                    <TreePine className="h-6 w-6 text-emerald-600" />
                    <h2 className="text-xl font-semibold ml-2">Environmental Factors</h2>
                  </div>
                  <p className="text-gray-600 mb-4">Understand how environmental conditions affect your roof's health.</p>
                  <Link to="/environmental" className="text-emerald-600 hover:text-emerald-700 font-medium">
                    Learn more →
                  </Link>
                </div>

                <div className="bg-white p-6 rounded-lg shadow-md">
                  <div className="flex items-center mb-4">
                    <HelpCircle className="h-6 w-6 text-emerald-600" />
                    <h2 className="text-xl font-semibold ml-2">FAQs</h2>
                  </div>
                  <p className="text-gray-600 mb-4">Get answers to common questions about roof moss removal and prevention.</p>
                  <Link to="/faq" className="text-emerald-600 hover:text-emerald-700 font-medium">
                    Learn more →
                  </Link>
                </div>
              </div>

              <section className="mb-12">
                <h2 className="text-2xl font-bold text-center mb-6">Get Your Personalized Assessment</h2>
                <AssessmentForm onSubmit={handleAssessment} />
              </section>
            </main>
          } />

          <Route path="/prevention" element={<PreventionPage />} />
          <Route path="/moisture" element={<MoisturePage />} />
          <Route path="/environmental" element={<EnvironmentalPage />} />
          <Route path="/faq" element={<FAQPage />} />
          <Route path="/zones" element={
            <main className="container mx-auto px-4 py-8">
              <h1 className="text-3xl font-bold mb-8">Climate Zone Recommendations</h1>
              <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
                {climateZones.map(zone => (
                  <div key={zone.id} className="bg-white rounded-lg shadow-md p-6">
                    <h2 className="text-xl font-semibold mb-2">{zone.name}</h2>
                    <p className="text-gray-600 mb-4">{zone.description}</p>
                    <div className={`inline-block px-3 py-1 rounded-full text-sm font-medium mb-4 ${
                      zone.riskLevel === 'high' ? 'bg-red-100 text-red-800' :
                      zone.riskLevel === 'medium' ? 'bg-yellow-100 text-yellow-800' :
                      'bg-green-100 text-green-800'
                    }`}>
                      {zone.riskLevel.charAt(0).toUpperCase() + zone.riskLevel.slice(1)} Risk
                    </div>
                    <ul className="space-y-2">
                      {zone.recommendations.map((rec, index) => (
                        <li key={index} className="flex items-start">
                          <span className="inline-block w-2 h-2 bg-emerald-500 rounded-full mt-2 mr-2" />
                          <span>{rec}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </main>
          } />
        </Routes>

        {assessmentResult && (
          <RecommendationDisplay
            condition={assessmentResult}
            onClose={() => setAssessmentResult(null)}
          />
        )}

        <footer className="bg-gray-800 text-white py-8 mt-12">
          <div className="container mx-auto px-4">
            <div className="text-center">
              <a href="tel:877-240-2506" className="text-xl font-bold hover:text-emerald-400">
                Call Us: (877) 240-2506
              </a>
              <p className="text-gray-400 mt-4">
                © {new Date().getFullYear()} NoMossRoof.com. All rights reserved.
                <br />
                <small>
                  For professional advice, always consult with a certified roofing contractor.
                </small>
              </p>
            </div>
          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;