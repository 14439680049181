import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft, Droplets, Wind, Sun, CloudRain } from 'lucide-react';

export default function MoisturePage() {
  return (
    <div className="container mx-auto px-4 py-8">
      <Link to="/" className="inline-flex items-center text-emerald-600 hover:text-emerald-700 mb-6">
        <ArrowLeft className="h-5 w-5 mr-2" />
        Back to Home
      </Link>

      <div className="max-w-4xl mx-auto">
        <div className="flex items-center mb-8">
          <Droplets className="h-10 w-10 text-emerald-600 mr-4" />
          <h1 className="text-3xl font-bold text-gray-900">Moisture Control</h1>
        </div>

        <div className="space-y-8">
          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-xl font-semibold mb-4">Essential Moisture Management</h2>
            <div className="grid md:grid-cols-2 gap-6">
              {[
                {
                  icon: <Wind className="h-6 w-6" />,
                  title: "Proper Ventilation",
                  description: "Ensure adequate airflow through attic and roof spaces"
                },
                {
                  icon: <CloudRain className="h-6 w-6" />,
                  title: "Drainage Systems",
                  description: "Maintain clean gutters and proper water drainage"
                },
                {
                  icon: <Sun className="h-6 w-6" />,
                  title: "Drying Conditions",
                  description: "Promote quick drying after rain through proper design"
                }
              ].map((item, index) => (
                <div key={index} className="flex items-start">
                  <span className="flex-shrink-0 p-2 bg-emerald-50 rounded-full text-emerald-600">
                    {item.icon}
                  </span>
                  <div className="ml-4">
                    <h3 className="font-medium text-gray-900">{item.title}</h3>
                    <p className="text-gray-600 mt-1">{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="bg-emerald-50 rounded-lg p-6">
            <h2 className="text-xl font-semibold mb-4">Impact of Moisture</h2>
            <p className="text-gray-700">
              Excess moisture is the primary catalyst for moss growth. Effective moisture control 
              through proper ventilation, drainage, and maintenance is crucial for preventing moss 
              and protecting your roof's structural integrity.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}