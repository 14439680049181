import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft, TreePine, Cloud, Sun, Thermometer } from 'lucide-react';

export default function EnvironmentalPage() {
  return (
    <div className="container mx-auto px-4 py-8">
      <Link to="/" className="inline-flex items-center text-emerald-600 hover:text-emerald-700 mb-6">
        <ArrowLeft className="h-5 w-5 mr-2" />
        Back to Home
      </Link>

      <div className="max-w-4xl mx-auto">
        <div className="flex items-center mb-8">
          <TreePine className="h-10 w-10 text-emerald-600 mr-4" />
          <h1 className="text-3xl font-bold text-gray-900">Environmental Factors</h1>
        </div>

        <div className="space-y-8">
          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-xl font-semibold mb-4">Key Environmental Influences</h2>
            <div className="grid md:grid-cols-2 gap-6">
              {[
                {
                  icon: <Cloud className="h-6 w-6" />,
                  title: "Climate Impact",
                  description: "Local weather patterns affect moss growth potential"
                },
                {
                  icon: <TreePine className="h-6 w-6" />,
                  title: "Surrounding Vegetation",
                  description: "Trees and plants influence shade and moisture levels"
                },
                {
                  icon: <Sun className="h-6 w-6" />,
                  title: "Sun Exposure",
                  description: "Direct sunlight helps prevent moss establishment"
                },
                {
                  icon: <Thermometer className="h-6 w-6" />,
                  title: "Temperature Patterns",
                  description: "Seasonal changes affect moss growth cycles"
                }
              ].map((item, index) => (
                <div key={index} className="flex items-start">
                  <span className="flex-shrink-0 p-2 bg-emerald-50 rounded-full text-emerald-600">
                    {item.icon}
                  </span>
                  <div className="ml-4">
                    <h3 className="font-medium text-gray-900">{item.title}</h3>
                    <p className="text-gray-600 mt-1">{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="bg-emerald-50 rounded-lg p-6">
            <h2 className="text-xl font-semibold mb-4">Managing Environmental Factors</h2>
            <p className="text-gray-700">
              Understanding and adapting to your local environmental conditions is crucial for 
              effective moss prevention. While you can't control the weather, you can take steps 
              to minimize its impact on your roof's susceptibility to moss growth.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}